import { bars } from './data'
import {
  TimelineDefinition,
  TimelineSegment,
} from '@motionone/dom/types/timeline/types'
import { TimelineOptions } from '@motionone/dom/types/timeline'

export const SETTINGS = {
  maxLoad: 210, // distance in px of the max load threshold line from the bottom of the container
  barWidth: 20, // in px
  barGap: 16, // in px, use only values that are divisible by 4 because of tailwind
  firstAnimatedBarIndex: 23, // first bar that is animated
  startTime: 8, // in hours, has to match the graphic
  restartTime: 13, // in hours, has to match the graphic
  visitorsLabelInitialPosition: 760, // in px
  visitorsLabelCharacterDistance: 12, // in px
  visitorsLabelStepDistance: 12, // in px
  baseAnimationDuration: 0.85, // in seconds
  pauseBeforeRestart: 0, // in millisecond
}

const darkGray = (opacity) => `rgba(59, 76, 106, ${opacity})`
const white = (opacity) => `rgba(255, 255, 255, ${opacity})`
const red = (opacity) => `rgba(255, 113, 94, ${opacity})`
const blue = (opacity) => `rgba(96, 139, 216, ${opacity})`
const purple = (opacity) => `rgb(155, 118, 220, ${opacity})`
const redTintedBlue = (opacity) => `rgba(149, 96, 216, ${opacity})`
const opacitySteps = {
  first: 1,
  second: 0.75,
  third: 0.6,
  fourth: 0.5,
}

const growBar = (i: number, onTimeline?: boolean): TimelineSegment => [
  `#homepage-animation-bar-${SETTINGS.firstAnimatedBarIndex + i}`,
  {
    transform: `translateY(0)`,
  },
  {
    at: onTimeline ? '<' : SETTINGS.baseAnimationDuration * i,
    duration: SETTINGS.baseAnimationDuration,
  },
]

const resetBar = (i: number): TimelineSegment => [
  `#homepage-animation-bar-${SETTINGS.firstAnimatedBarIndex + i}`,
  {
    transform: `translateY(${
      bars[SETTINGS.firstAnimatedBarIndex + i].height
    }px)`,
  },
  {
    at: '<',
    duration: SETTINGS.baseAnimationDuration / 3,
  },
]

const growPhantomBar = (i: number, onTimeline?: boolean): TimelineSegment => [
  `#homepage-animation-phantom-bar-${SETTINGS.firstAnimatedBarIndex + i}`,
  {
    transform: `translateY(0)`,
  },
  {
    at: onTimeline ? '<' : SETTINGS.baseAnimationDuration * i,
    duration: SETTINGS.baseAnimationDuration,
  },
]

const resetPhantomBar = (i: number): TimelineSegment => [
  `#homepage-animation-phantom-bar-${SETTINGS.firstAnimatedBarIndex + i}`,
  {
    transform: `translateY(${
      bars[SETTINGS.firstAnimatedBarIndex + i].phantomHeight
    }px)`,
  },
  {
    at: '<',
    duration: SETTINGS.baseAnimationDuration,
  },
]

const changeBarColor = (
  i: number,
  color: string,
  fast = false,
): TimelineSegment => [
  `#homepage-animation-bar-${SETTINGS.firstAnimatedBarIndex + i}`,
  {
    background: color,
  },
  {
    at: '<',
    duration: fast
      ? SETTINGS.baseAnimationDuration / 4
      : SETTINGS.baseAnimationDuration,
  },
]

const moveVisitorsLabel = (i: number, offset?: number): TimelineSegment => [
  `.homepage-animation-visitors-label-character`,
  {
    offsetDistance: `calc(${
      SETTINGS.visitorsLabelInitialPosition +
      (SETTINGS.barGap +
        SETTINGS.barWidth +
        SETTINGS.visitorsLabelStepDistance) *
        (i + 1)
    }px + var(--i) * ${SETTINGS.visitorsLabelCharacterDistance}px + ${
      offset ? offset : 0
    }px)`,
  },
  {
    at: '<',
    duration: SETTINGS.baseAnimationDuration / 3,
  },
]

// Changes visibility of a specific time label
const setTimeVisibility = (i: number, show: boolean): TimelineSegment => [
  `#homepage-animation-bar-${SETTINGS.firstAnimatedBarIndex + i}-time`,
  {
    opacity: show ? 1 : 0,
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration / 4 },
]

// Moves content (bars, time labels and the background graph) to the left
const step = (i: number): TimelineSegment => [
  `#homepage-animation-content, #homepage-animation-graph-visitors-group`,
  {
    transform: `translateX(${
      (SETTINGS.barGap + SETTINGS.barWidth) * (i + 1) * -1
    }px)`,
  },
  { duration: SETTINGS.baseAnimationDuration / 4 },
]

// Moves countup timer to the right
const moveTimer = (i: number): TimelineSegment => [
  `#homepage-animation-timer`,
  {
    transform: `translateX(${(SETTINGS.barGap + SETTINGS.barWidth) * i}px)`,
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration / 4 },
]

// Warning state related animations
const changeBlueBarsColor = (color: string): TimelineSegment => [
  `.homepage-animation-blue-bars`,
  {
    background: color,
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration },
]

const changeTextColor = (color: string): TimelineSegment => [
  `.homepage-animation-text`,
  {
    color: color,
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration },
]

const changeTimerColor = (color: string): TimelineSegment => [
  `#homepage-animation-timer`,
  {
    color: color,
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration },
]

const changeLineColor = (color: string): TimelineSegment => [
  `#homepage-animation-line`,
  {
    color: color,
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration },
]

const changeGraphColor = (color: string): TimelineSegment => [
  `#homepage-animation-background-graph`,
  {
    color: color,
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration },
]

const changeFilterColor = (color: string, slow = true): TimelineSegment => [
  `#homepage-animation-filter`,
  {
    background: color,
  },
  {
    at: '<',
    duration: slow
      ? SETTINGS.baseAnimationDuration
      : SETTINGS.baseAnimationDuration / 3,
  },
]

// Related to the terminal overlay
const showTerminalOverlay = (): TimelineSegment => [
  `#homepage-animation-terminal-overlay`,
  {
    opacity: 1,
    transform: 'scale(1)',
  },
  { duration: SETTINGS.baseAnimationDuration / 3 },
]

const hideTerminalOverlay = (): TimelineSegment => [
  `#homepage-animation-terminal-overlay`,
  {
    opacity: 0,
    transform: 'scale(0.75)',
  },
  { duration: SETTINGS.baseAnimationDuration / 3 },
]

const changeWhiteFilterColor = (color: string): TimelineSegment => [
  `#homepage-animation-white-filter`,
  {
    background: color,
  },
  {
    at: '<',
    duration: SETTINGS.baseAnimationDuration / 4,
  },
]

const switchEndpoint = (): TimelineSegment => [
  `#homepage-animation-endpoint`,
  {
    transform: 'translateY(-48px)',
  },
  {
    duration: SETTINGS.baseAnimationDuration / 3,
  },
]

const resetEndpoint = (): TimelineSegment => [
  `#homepage-animation-endpoint`,
  {
    transform: 'translateY(0)',
  },
  {
    at: '<',
    duration: SETTINGS.baseAnimationDuration / 3,
  },
]

// Related to the Stellate activated overlay
const showStellateOverlay = (): TimelineSegment => [
  `#homepage-animation-stellate-overlay`,
  {
    opacity: 1,
    transform: 'scale(1)',
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration / 3 },
]

const hideStellateOverlay = (): TimelineSegment => [
  `#homepage-animation-stellate-overlay`,
  {
    opacity: 0,
    transform: 'scale(0.75)',
  },
  {
    at: '<',
    duration: SETTINGS.baseAnimationDuration / 3,
  },
]

const showStellateIndicator = (): TimelineSegment => [
  `#homepage-animation-stellate-indicator`,
  {
    opacity: 1,
  },
  { duration: SETTINGS.baseAnimationDuration / 3 },
]

const hideStellateIndicator = (): TimelineSegment => [
  `#homepage-animation-stellate-indicator`,
  {
    opacity: 0,
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration / 3 },
]

const changeStellateLabelColor = (color: string): TimelineSegment => [
  `#homepage-animation-stellate-label`,
  {
    color: color,
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration },
]

// Use to make gaps and/or extend the animation
const timeFiller = (delay: number): TimelineSegment => [
  '#homepage-animation', // must select an element
  { background: 'white' }, // must animate something (this actually doesn't do anything since the background is already white)
  { duration: delay },
]

// Related to the DDOS bar animation
const growDDOSBar = (i: number, onTimeline?: boolean): TimelineSegment => [
  `#homepage-animation-bar-${SETTINGS.firstAnimatedBarIndex + i}`,
  {
    height: `${bars[SETTINGS.firstAnimatedBarIndex + i].height}px`,
  },
  {
    at: onTimeline ? '<' : SETTINGS.baseAnimationDuration * i,
    duration: SETTINGS.baseAnimationDuration,
  },
]

const resetDDOSBar = (i: number): TimelineSegment => [
  `#homepage-animation-bar-${SETTINGS.firstAnimatedBarIndex + i}`,
  {
    height: 0,
  },
  {
    at: '<',
    duration: SETTINGS.baseAnimationDuration / 3,
  },
]

const showDDOSHighlight = (): TimelineSegment => [
  `#homepage-animation-ddos-highlight`,
  {
    opacity: 1,
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration / 2 },
]

const hideDDOSHighlight = (): TimelineSegment => [
  `#homepage-animation-ddos-highlight`,
  {
    opacity: 0,
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration / 3 },
]

const showDDOSLabel = (): TimelineSegment => [
  `#homepage-animation-ddos-label`,
  {
    opacity: 1,
    transform: 'scale(1)',
  },
  { duration: SETTINGS.baseAnimationDuration / 2 },
]

const hideDDOSLabel = (): TimelineSegment => [
  `#homepage-animation-ddos-label`,
  {
    opacity: 0,
    transform: 'scale(0.75)',
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration / 3 },
]

const showDDOSNotification = (): TimelineSegment => [
  `#homepage-animation-ddos-notification`,
  {
    opacity: 1,
    transform: 'translateY(0)',
  },
  { at: '+0.6', duration: SETTINGS.baseAnimationDuration * 0.75 },
]

const hideDDOSNotification = (): TimelineSegment => [
  `#homepage-animation-ddos-notification`,
  {
    opacity: 0,
    transform: 'translateY(16px)',
  },
  { at: '<', duration: SETTINGS.baseAnimationDuration / 3 },
]

// Related to the dashboard animation
const hideStage = (): TimelineSegment => [
  '#homepage-animation',
  { opacity: 0 },
  { duration: SETTINGS.baseAnimationDuration },
]

const showStage = (): TimelineSegment => [
  '#homepage-animation',
  { opacity: 1 },
  { duration: SETTINGS.baseAnimationDuration },
]

const resetDashboard = (): TimelineSegment => [
  '#homepage-animation-dashboard',
  { transform: 'scale(1.5)' },
  { at: '<', duration: SETTINGS.baseAnimationDuration },
]

// Animation until overlay
export const firstPhaseSequence: TimelineDefinition = [
  // First bar animation
  growBar(0),
  changeBarColor(0, blue(opacitySteps.first)),
  step(0),
  moveVisitorsLabel(0),
  changeBarColor(0, blue(opacitySteps.second), true),
  moveTimer(1),
  // Second bar animation
  growBar(1),
  changeBarColor(1, blue(opacitySteps.first)),
  step(1),
  moveVisitorsLabel(1, 25),
  changeBarColor(0, blue(opacitySteps.third), true),
  changeBarColor(1, blue(opacitySteps.second), true),
  setTimeVisibility(0, true),
  moveTimer(2),
  // Third bar animation
  growBar(2),
  changeBarColor(2, red(opacitySteps.first)),
  // START: trigger warning state colors
  changeGraphColor(red(0.1)),
  changeFilterColor(red(0.05)),
  changeBlueBarsColor(redTintedBlue(opacitySteps.fourth)),
  changeTextColor(red(0.3)),
  changeStellateLabelColor(red(1)),
  changeTimerColor(red(opacitySteps.third)),
  changeLineColor(red(0.15)),
  // END
  step(2),
  moveVisitorsLabel(2, 50),
  changeBarColor(2, red(opacitySteps.second), true),
  setTimeVisibility(4, false),
  moveTimer(3),
  // Fourth bar animation
  growBar(3),
  changeBarColor(3, red(opacitySteps.first)),
  step(3),
  moveVisitorsLabel(3, 65),
  changeBarColor(2, red(opacitySteps.third), true),
  changeBarColor(3, red(opacitySteps.second), true),
  moveTimer(4),
  // Fifth bar animation
  growBar(4),
  changeBarColor(4, red(opacitySteps.first)),
]

// Show overlay
export const secondPhaseSequence: TimelineDefinition = [
  showTerminalOverlay(),
  changeWhiteFilterColor(white(0.4)),
  timeFiller(SETTINGS.baseAnimationDuration / 2),
]

// Hide overlay
export const thirdPhaseSequence: TimelineDefinition = [
  // START: remove warning state color
  switchEndpoint(),
  changeFilterColor(blue(0.2)),
  changeBlueBarsColor(blue(0.5)),
  changeTextColor(darkGray(0.3)),
  changeStellateLabelColor(blue(0.3)),
  changeTimerColor(darkGray(opacitySteps.third)),
  changeLineColor(darkGray(0.15)),
  changeGraphColor(darkGray(0.05)),
  // END
  hideTerminalOverlay(),
  changeWhiteFilterColor('transparent'),
  showStellateIndicator(),
  showStellateOverlay(),
]

// Animation till DDOS attack
export const fourthPhaseSequence: TimelineDefinition = [
  // Sixth bar animation
  step(4),
  moveVisitorsLabel(4, 60),
  changeBarColor(2, red(opacitySteps.fourth), true),
  changeBarColor(3, red(opacitySteps.third), true),
  changeBarColor(4, red(opacitySteps.second), true),
  moveTimer(5),
  growBar(5, true),
  growPhantomBar(5, true),
  changeBarColor(5, blue(opacitySteps.first)),
  // Seventh bar animation
  step(5),
  moveVisitorsLabel(5, 55),
  changeBarColor(3, red(opacitySteps.fourth), true),
  changeBarColor(4, red(opacitySteps.third), true),
  changeBarColor(5, blue(opacitySteps.second), true),
  setTimeVisibility(4, true),
  moveTimer(6),
  growBar(6, true),
  changeFilterColor('transparent', true),
  changeStellateLabelColor(blue(1)),
  hideStellateOverlay(),
  growPhantomBar(6, true),
  changeBarColor(6, blue(opacitySteps.first)),
  // Eight bar animation
  step(6),
  moveVisitorsLabel(6, 40),
  changeBarColor(4, red(opacitySteps.fourth), true),
  changeBarColor(5, blue(opacitySteps.third), true),
  changeBarColor(6, blue(opacitySteps.second), true),
  setTimeVisibility(8, false),
  moveTimer(7),
  growBar(7, true),
  growPhantomBar(7, true),
  changeBarColor(7, blue(opacitySteps.first)),
  // Ninth bar animation
  step(7),
  moveVisitorsLabel(7, 25),
  changeBarColor(5, blue(opacitySteps.fourth), true),
  changeBarColor(6, blue(opacitySteps.third), true),
  changeBarColor(7, blue(opacitySteps.second), true),
  moveTimer(8),
  growBar(8, true),
  growPhantomBar(8, true),
  changeBarColor(8, blue(opacitySteps.first)),
  // Tenth bar animation (DDOS)
  step(8),
  moveVisitorsLabel(8, 15),
  changeBarColor(6, blue(opacitySteps.fourth), true),
  changeBarColor(7, blue(opacitySteps.third), true),
  changeBarColor(8, blue(opacitySteps.second), true),
  moveTimer(9),
  growDDOSBar(9, true),
  changeBarColor(9, purple(opacitySteps.first)),
  changeTimerColor(red(opacitySteps.first)),
  showDDOSHighlight(),
]

// Animation till the dashboard
export const fifthPhaseSequence: TimelineDefinition = [
  showDDOSLabel(),
  showDDOSNotification(),
  changeWhiteFilterColor(darkGray(0.15)),
  timeFiller(SETTINGS.baseAnimationDuration * 2),
  hideStage(),
  hideDDOSNotification(),
  changeWhiteFilterColor('transparent'),
]

// Reset every prior animation
export const resetPhaseSequence: TimelineDefinition = [
  // Dashboard + stage
  step(-1),
  // Timer
  moveTimer(0),
  resetDashboard(),
  setTimeVisibility(0, false),
  setTimeVisibility(8, true),
  changeTimerColor(darkGray(opacitySteps.third)),
  // Top right label
  hideStellateIndicator(),
  changeStellateLabelColor(darkGray(0.3)),
  // Visitors label
  moveVisitorsLabel(-1),
  // Endpoint
  resetEndpoint(),
  // Bars
  resetBar(0),
  changeBarColor(0, blue(0)),
  resetBar(1),
  resetBar(2),
  changeBarColor(2, blue(opacitySteps.fourth)),
  resetBar(3),
  changeBarColor(3, blue(opacitySteps.fourth)),
  resetBar(4),
  changeBarColor(4, blue(opacitySteps.fourth)),
  resetBar(5),
  resetPhantomBar(5),
  resetBar(6),
  resetPhantomBar(6),
  changeBarColor(6, blue(opacitySteps.fourth)),
  resetBar(7),
  resetPhantomBar(7),
  changeBarColor(7, blue(opacitySteps.fourth)),
  resetBar(8),
  resetPhantomBar(8),
  changeBarColor(8, blue(opacitySteps.fourth)),
  resetDDOSBar(9),
  changeBarColor(9, blue(opacitySteps.fourth)),
  hideDDOSHighlight(),
  hideDDOSLabel(),
  showStage(),
]

// Motion animation options
export const options: TimelineOptions = {
  defaultOptions: {
    easing: [0.83, 0, 0.17, 1],
  },
}
