import {
  MutableRefObject,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react'
import { timeline } from 'motion'
import { bars } from './data'
import {
  SETTINGS,
  firstPhaseSequence,
  secondPhaseSequence,
  thirdPhaseSequence,
  fourthPhaseSequence,
  fifthPhaseSequence,
  resetPhaseSequence,
  options,
} from './animation'
import { AnimationControls } from 'motion/types'
import BackgroundGraph from './BackgroundGraph'
import Triangle from './Triangle'
import Tick from './Tick'
import DashedLine from './DashedLine'
import DottedLine from './DottedLine'
import ExclamationMark from './ExclamationMark'
import { LogoEmblem } from '../../../icons/LogoEmblem'
import useIsElementInView from 'scripts/hooks/useIsElementInView'
import { FEATURE_EDGE_CACHING } from 'common/constants'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

// These must be adjusted together since they influence each other
const initialContentTranslation =
  '-translate-x-164 xs:-translate-x-150 sm:-translate-x-110 md:-translate-x-80 lg:-translate-x-40 xl:translate-x-0'
const rigthFadePosition =
  'left-56 xs:left-62 sm:left-96 md:left-120 lg:left-164 xl:left-207'

const HomepageAnimation = ({ className, ...props }: Props): JSX.Element => {
  const inViewRef = useRef() as MutableRefObject<HTMLDivElement>
  const animationIsInView = useIsElementInView(inViewRef)
  const firstPhase = useRef() as MutableRefObject<AnimationControls>
  const secondPhase = useRef() as MutableRefObject<AnimationControls>
  const thirdPhase = useRef() as MutableRefObject<AnimationControls>
  const fourthPhase = useRef() as MutableRefObject<AnimationControls>
  const fifthPhase = useRef() as MutableRefObject<AnimationControls>
  const resetPhase = useRef() as MutableRefObject<AnimationControls>
  const requestAnimationRef = useRef(0)
  const timeoutRef = useRef() as MutableRefObject<ReturnType<typeof setTimeout>>
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [withStellate, setWithStellate] = useState(false)
  const [animationIsRunning, setAnimationIsRunning] = useState(false)

  const reset = () => {
    timeoutRef.current = setTimeout(() => {
      setHours(SETTINGS.startTime)
      setMinutes(0)
      setWithStellate(false)
      resetPhase.current = timeline(resetPhaseSequence, options)
      resetPhase.current.finished.then(() => {
        setAnimationIsRunning(false)
      })
    }, SETTINGS.pauseBeforeRestart)

    return () => clearTimeout(timeoutRef.current)
  }

  const animation = useCallback(() => {
    setAnimationIsRunning(true)
    firstPhase.current = timeline(firstPhaseSequence, options)
    firstPhase.current.finished.then(() => {
      secondPhase.current = timeline(secondPhaseSequence, options)
      secondPhase.current.finished.then(() => {
        setWithStellate(true)
        thirdPhase.current = timeline(thirdPhaseSequence, options)
        thirdPhase.current.finished.then(() => {
          fourthPhase.current = timeline(fourthPhaseSequence, options)
          fourthPhase.current.finished.then(() => {
            fifthPhase.current = timeline(fifthPhaseSequence, options)
            fifthPhase.current.finished.then(() => reset())
          })
        })
      })
    })
  }, [])

  useEffect(() => {
    if (!animationIsRunning && animationIsInView) {
      animation()
    }
  }, [animationIsInView, animation, animationIsRunning])

  useEffect(() => {
    if (animationIsRunning) {
      const frame = () => {
        if (firstPhase.current.currentTime != 0) {
          setHours(
            Math.floor(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              firstPhase.current.currentTime! / SETTINGS.baseAnimationDuration +
                SETTINGS.startTime,
            ),
          )
          setMinutes(
            Math.floor(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ((firstPhase.current.currentTime! /
                SETTINGS.baseAnimationDuration) %
                1) *
                60,
            ),
          )
        } else if (
          fourthPhase.current &&
          fourthPhase.current.currentTime != 0
        ) {
          setHours(
            Math.floor(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              fourthPhase.current.currentTime! /
                SETTINGS.baseAnimationDuration +
                SETTINGS.restartTime,
            ),
          )
          setMinutes(
            Math.floor(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ((fourthPhase.current.currentTime! /
                SETTINGS.baseAnimationDuration) %
                1) *
                60,
            ),
          )
        }

        requestAnimationRef.current = requestAnimationFrame(frame)
      }

      frame()

      return () => {
        cancelAnimationFrame(requestAnimationRef.current)
      }
    }
  }, [animationIsRunning])

  return (
    <div className='relative h-130 sm:h-150' ref={inViewRef}>
      <div
        className={`relative overflow-hidden rounded-12 mobile-rounded-overflow-fix bg-white h-full shadow-small font-demibold leading-snug ${
          className || ''
        }`}
        id='homepage-animation'
        {...props}
      >
        {/* Background graph + visitors label group */}
        <div className='absolute w-full h-full'>
          <div
            className={`relative w-full h-full ${initialContentTranslation}`}
          >
            <div
              id='homepage-animation-graph-visitors-group'
              className='w-full h-full'
            >
              {/* Background graph */}
              <BackgroundGraph
                id='homepage-animation-background-graph'
                className='absolute bottom-0 left-0 fill-current text-neutral-7'
              />

              {/* Visitors label */}
              <div className='homepage-animation-text whitespace-pre-wrap flex -translate-x-3 -translate-y-33 sm:-translate-y-3 w-fit-content label text-neutral-25'>
                <div
                  className='homepage-animation-visitors-label-character'
                  style={{
                    offsetDistance: `calc(${SETTINGS.visitorsLabelInitialPosition}px + var(--i) * ${SETTINGS.visitorsLabelCharacterDistance}px)`,
                  }}
                >
                  <span>V</span>
                </div>
                <div
                  className='homepage-animation-visitors-label-character'
                  style={{
                    offsetDistance: `calc(${SETTINGS.visitorsLabelInitialPosition}px + var(--i) * ${SETTINGS.visitorsLabelCharacterDistance}px)`,
                  }}
                >
                  <span>I</span>
                </div>
                <div
                  className='homepage-animation-visitors-label-character'
                  style={{
                    offsetDistance: `calc(${SETTINGS.visitorsLabelInitialPosition}px + var(--i) * ${SETTINGS.visitorsLabelCharacterDistance}px)`,
                  }}
                >
                  <span>S</span>
                </div>
                <div
                  className='homepage-animation-visitors-label-character'
                  style={{
                    offsetDistance: `calc(${SETTINGS.visitorsLabelInitialPosition}px + var(--i) * ${SETTINGS.visitorsLabelCharacterDistance}px)`,
                  }}
                >
                  <span>I</span>
                </div>
                <div
                  className='homepage-animation-visitors-label-character'
                  style={{
                    offsetDistance: `calc(${SETTINGS.visitorsLabelInitialPosition}px + var(--i) * ${SETTINGS.visitorsLabelCharacterDistance}px)`,
                  }}
                >
                  <span>T</span>
                </div>
                <div
                  className='homepage-animation-visitors-label-character'
                  style={{
                    offsetDistance: `calc(${SETTINGS.visitorsLabelInitialPosition}px + var(--i) * ${SETTINGS.visitorsLabelCharacterDistance}px)`,
                  }}
                >
                  <span>O</span>
                </div>
                <div
                  className='homepage-animation-visitors-label-character'
                  style={{
                    offsetDistance: `calc(${SETTINGS.visitorsLabelInitialPosition}px + var(--i) * ${SETTINGS.visitorsLabelCharacterDistance}px)`,
                  }}
                >
                  <span>R</span>
                </div>
                <div
                  className='homepage-animation-visitors-label-character'
                  style={{
                    offsetDistance: `calc(${SETTINGS.visitorsLabelInitialPosition}px + var(--i) * ${SETTINGS.visitorsLabelCharacterDistance}px)`,
                  }}
                >
                  <span>S</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right gradient */}
        <div
          className={`absolute flex h-full right-0 bottom-0 top-0 ${rigthFadePosition}`}
        >
          <span className='transparent-to-white flex-shrink-0 h-full w-10' />
          <span className='bg-white h-full w-150' />
        </div>

        {/* Threshold */}
        <div className='absolute w-full h-3/4 sm:h-4/5 bottom-14'>
          <div className='relative w-full h-full'>
            {/* Max load label */}
            <div
              className='absolute w-full flex items-end space-x-4 left-0'
              style={{ bottom: `${SETTINGS.maxLoad}px` }}
            >
              <span className='homepage-animation-text ml-4 translate-y-2 label text-neutral-25'>
                MAX LOAD
              </span>
              <div className='relative flex-grow h-0.5 overflow-hidden'>
                <DashedLine
                  id='homepage-animation-line'
                  className='absolute left-0 top-0 h-0.5 text-neutral-15'
                />
              </div>
            </div>
          </div>
        </div>

        {/* Main content, bars + time labels */}
        <div
          className='absolute h-3/4 sm:h-4/5 bottom-14'
          id='homepage-animation-content'
        >
          <div
            className={`relative w-full h-full ${initialContentTranslation}`}
          >
            <div
              className={`relative flex items-end space-x-${
                SETTINGS.barGap / 4
              } w-full h-full`}
            >
              {bars.map((bar, index) => (
                <div
                  key={`bar-${index}`}
                  className='relative '
                  style={{
                    width: SETTINGS.barWidth,
                  }}
                >
                  {/* Time labels */}
                  {bar.time && (
                    <span
                      id={`homepage-animation-bar-${index}-time`}
                      className={`homepage-animation-text absolute label flex justify-center text-neutral-25 -bottom-10 w-5 ${
                        index === SETTINGS.firstAnimatedBarIndex
                          ? 'opacity-0'
                          : ''
                      }`}
                    >
                      {bar.time}
                    </span>
                  )}

                  {/* Phantom bars */}
                  {bar.phantomHeight && (
                    <div
                      className='relative overflow-hidden rounded'
                      style={{
                        height: bar.phantomHeight,
                        width: SETTINGS.barWidth,
                        bottom: `-${bar.height}px`,
                      }}
                    >
                      <span
                        id={`homepage-animation-phantom-bar-${index}`}
                        className='absolute bottom-0 w-full rounded bg-neutral-7'
                        style={{
                          height: bar.phantomHeight,
                          transform: `translateY(${bar.phantomHeight}px)`,
                        }}
                      />
                    </div>
                  )}

                  {/* Bars */}
                  {bar.bgColor !== 'ddos' ? (
                    <div
                      className='relative overflow-hidden rounded'
                      style={{
                        height: bar.height,
                        width: SETTINGS.barWidth,
                      }}
                    >
                      <div
                        id={`homepage-animation-bar-${index}`}
                        className={`${
                          index < SETTINGS.firstAnimatedBarIndex + 2
                            ? 'homepage-animation-blue-bars'
                            : ''
                        } absolute bottom-0 left-0 w-full rounded ${
                          bar.bgColor === 'red' ? 'bg-red-60' : 'bg-blue-60'
                        }`}
                        style={{
                          height: bar.height,
                          transform:
                            index < SETTINGS.firstAnimatedBarIndex
                              ? 'translateY(0)'
                              : `translateY(${bar.height}px)`,
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      {/* DDOS section highlight */}
                      <span
                        id='homepage-animation-ddos-highlight'
                        className='absolute -bottom-14 w-full h-130 sm:h-150 bg-red-10 opacity-0'
                      />

                      {/* DDOS bar  */}
                      <div
                        id={`homepage-animation-bar-${index}`}
                        className='absolute rounded bottom-0 overflow-hidden bg-blue-60'
                        style={{
                          width: SETTINGS.barWidth,
                          height: 0,
                          willChange: 'height',
                        }}
                      >
                        <span className='absolute bottom-16 bg-red w-full h-3' />
                        <span className='absolute bottom-44 bg-red w-full h-4' />
                        <span className='absolute bottom-52 bg-red w-full h-3' />
                        <span className='absolute bottom-64 bg-red w-full h-10' />
                      </div>

                      {/* DDOS label */}
                      <div
                        id='homepage-animation-ddos-label'
                        className='absolute flex items-center space-x-1.5 bg-white shadow-small opacity-0 scale-75 rounded-full p-1 -left-24 sm:left-2'
                        style={{ bottom: `${bar.height - 16}px` }}
                      >
                        <ExclamationMark />
                        <span className='block text-red mr-2 mt-px whitespace-nowrap leading-none'>
                          DDOS ATTACK
                        </span>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>

            {/* Timer */}
            <div
              id='homepage-animation-timer'
              className='absolute label flex justify-center text-neutral-60 -bottom-10 left-207 w-5'
            >
              <div className='relative'>
                <span className='tabular-nums'>
                  {`${hours.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}:${minutes.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}`}
                </span>

                <Triangle className='absolute fill-current right-1/2 translate-x-1/2 -bottom-4' />
              </div>
            </div>

            {/* With Stelalte indicator */}
            <div
              id='homepage-animation-stellate-indicator'
              className='absolute flex w-full space-x-3 opacity-0 items-center -top-11 sm:-top-8 text-blue-60'
              style={{
                left:
                  (SETTINGS.firstAnimatedBarIndex + 5) *
                  (SETTINGS.barGap + SETTINGS.barWidth),
              }}
            >
              <Triangle className='fill-current flex-shrink-0 rotate-90 w-3 h-auto ' />

              <div className='relative w-full h-3px'>
                <DottedLine className='absolute left-0 top-0 h-3px text-blue-60' />
              </div>
            </div>
          </div>
        </div>

        {/* Top-right corner label */}
        <div
          id='homepage-animation-stellate-label'
          className='absolute right-0 bg-white pl-2 pr-4 sm:pr-6 top-6 text-neutral-25'
        >
          {withStellate
            ? `With Stellate’s ${FEATURE_EDGE_CACHING}`
            : `Without Stellate’s ${FEATURE_EDGE_CACHING}`}
        </div>

        {/* Color filters */}
        <div
          id='homepage-animation-filter'
          className='absolute rounded-12 h-full w-full'
        />

        <div
          id='homepage-animation-white-filter'
          className='absolute rounded-12 h-full w-full'
        />

        {/* Terminal overlay */}
        <div className='absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 overflow-x-hidden max-w-9/10'>
          <div
            id='homepage-animation-terminal-overlay'
            className='relative opacity-0 scale-75 py-3 sm:py-4 md:py-5 bg-neutral rounded-12'
          >
            <span className='absolute text0neutral-on-dark-40 small-body-text top-1 right-4'>
              .env
            </span>
            <code className='leading-loose whitespace-nowrap large-body-text text-neutral-on-dark-25'>
              <span className='block px-4 sm:px-8'>
                GA_ID: &quot;GTM-ORHNK2&quot;
              </span>
              <span className='block px-4 sm:px-8'>
                NEXT_STATIC_PORTAL_ID: &quot;23423498&quot;
              </span>
              <div className='px-4 sm:px-8 flex flex-col sm:flex-row sm:space-x-2 bg-neutral-110 sm:items-center whitespace-normal sm:whitespace-nowrap'>
                <span className='text-white'>API_ENDPOINT:</span>

                <div className='inline-block relative pr-4 overflow-hidden'>
                  <div id='homepage-animation-endpoint'>
                    <span className='text-red'>
                      &quot;https://acme.com/graphql&quot;
                    </span>
                    <span className='absolute left-0 bottom-0 translate-y-12 text-blue'>
                      &quot;https://acme.stellate.sh&quot;
                    </span>
                  </div>
                </div>
              </div>
              <span className='block px-4 sm:px-8'>
                ORBIT_AUTH_KEY: &quot;ob_23ksdklsadfk&quot;
              </span>
            </code>
          </div>
        </div>

        {/* Stellate activated overlay */}
        <div className='absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2'>
          <div
            id='homepage-animation-stellate-overlay'
            className='relative opacity-0 scale-75 flex items-center rounded-12 space-x-5 bg-blue card-padding'
          >
            <Tick className='flex-shrink-0 w-8 sm:w-12 h-auto' />
            <span className='text-white whitespace-nowrap h4-size'>
              Stellate Edge Cache activated
            </span>
          </div>
        </div>
      </div>

      {/* DDOS notification */}
      <div
        id='homepage-animation-ddos-notification'
        className='absolute bg-white translate-y-4 -bottom-4 -right-2 md:-right-14 rounded-12 shadow-small flex space-x-3 p-5 opacity-0 font-demibold small-body-text max-w-280 sm:max-w-sm'
      >
        <LogoEmblem className='h-7 sm:h-9 w-auto flex-shrink-0' />

        <div>
          <span>Stellate</span>
          <span className='relative bottom-px ml-1 small-body-text bg-neutral-10 px-1 rounded-sm'>
            APP
          </span>
          <span className='ml-2 small-body-text text-neutral-40'>17:42</span>
          <div className='spacer-4xs text-neutral-60'>
            🚨 DDoS warning triggered by unusually high request loads. Check
            your Dashboard.
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomepageAnimation
