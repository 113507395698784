export const bars = [
  {
    height: 113,
  },
  {
    height: 113,
  },
  {
    height: 117,
  },
  {
    height: 122,
    time: '12:00',
  },
  {
    height: 128,
  },
  {
    height: 132,
  },
  {
    height: 132,
  },
  {
    height: 127,
    time: '16:00',
  },
  {
    height: 118,
  },
  {
    height: 110,
  },
  {
    height: 106,
  },
  {
    height: 108,
    time: '20:00',
  },
  {
    height: 116,
  },
  {
    height: 128,
  },
  {
    height: 142,
  },
  {
    height: 154,
    time: '24:00',
  },
  {
    height: 163,
  },
  {
    height: 167,
  },
  {
    height: 165,
  },
  {
    height: 155,
    time: '04:00',
  },
  {
    height: 146,
  },
  {
    height: 142,
  },
  {
    height: 145,
  },
  {
    height: 155,
    time: '08:00',
  },
  {
    height: 180,
  },
  // START: red section
  {
    height: 234,
  },
  {
    height: 317,
    bgColor: 'red',
  },
  {
    height: 354,
    bgColor: 'red',
    time: '12:00',
  },
  // END: red section
  {
    height: 64,
    phantomHeight: 361,
  },
  {
    height: 68,
    phantomHeight: 362,
  },
  {
    height: 62,
    phantomHeight: 364,
  },
  {
    height: 59,
    phantomHeight: 368,
    time: '16:00',
  },
  {
    height: 290,
    bgColor: 'ddos',
  },
  {
    height: 71,
    phantomHeight: 382,
  },
  {
    height: 74,
    phantomHeight: 392,
  },
  {
    height: 76,
    phantomHeight: 406,
    time: '20:00',
  },
  {
    height: 78,
    phantomHeight: 423,
  },
  {
    height: 75,
    phantomHeight: 443,
  },
  {
    height: 82,
    phantomHeight: 468,
  },
  {
    height: 88,
    phantomHeight: 495,
    time: '24:00',
  },
]
