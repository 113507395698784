const DottedLine = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    viewBox='0 0 300 3'
    xmlns='http://www.w3.org/3000/svg'
    width={300}
    height={3}
    {...props}
  >
    <defs>
      <pattern
        id='animation-dotted-line'
        width='8'
        height='3'
        // eslint-disable-next-line react/no-unknown-property
        fill='none'
        viewBox='0 0 8 3'
        patternUnits='userSpaceOnUse'
      >
        <circle cx='1.5' cy='1.5' r='1.5' fill='currentColor' />
      </pattern>
    </defs>

    <rect width='300' height='3' fill='url(#animation-dotted-line)' />
  </svg>
)

export default DottedLine
