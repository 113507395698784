const DashedLine = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    viewBox='0 0 1600 2'
    xmlns='http://www.w3.org/2000/svg'
    width={1600}
    height={2}
    {...props}
  >
    <defs>
      <pattern
        id='animation-dashed-line'
        width='28'
        height='2'
        // eslint-disable-next-line react/no-unknown-property
        fill='none'
        viewBox='0 0 28 2'
        patternUnits='userSpaceOnUse'
      >
        <line
          x1='1'
          x2='15'
          y1='1'
          y2='1'
          stroke='currentColor'
          strokeLinecap='round'
          strokeWidth='2'
        />
      </pattern>
    </defs>

    <rect width='1600' height='2' fill='url(#animation-dashed-line)' />
  </svg>
)

export default DashedLine
