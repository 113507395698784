import { useState, useEffect } from 'react'

const useIsElementInView = (
  target: React.RefObject<HTMLElement>,
  threshold = 0.7,
) => {
  const [isInView, setIsInView] = useState(false)

  useEffect(() => {
    if (typeof IntersectionObserver === 'undefined') {
      return
    }

    if (target.current) {
      let callback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          setIsInView(entry.isIntersecting)
        })
      }

      const options = {
        root: null,
        rootMargin: '0px',
        threshold: threshold,
      }

      const intersectionObserver = new IntersectionObserver(callback, options)

      intersectionObserver.observe(target.current)

      return () => {
        if (target.current) {
          intersectionObserver.unobserve(target.current)
        }
      }
    }
  }, [target])

  return isInView
}

export default useIsElementInView
