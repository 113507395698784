export function LogoEmblem({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 350 392'
      width={350}
      height={392}
      {...props}
    >
      <path
        fill='#4568a5'
        fillRule='evenodd'
        d='m330.4 308.35.27-.16a27.66 27.66 0 0 0 10.21-36.75l-31.78-60.28L69.8 337.33l85.6 49.42a39.21 39.21 0 0 0 39.2 0ZM43.09 243.33l192-214.71L194.6 5.25a39.21 39.21 0 0 0-39.2 0L19.6 83.65A39.2 39.2 0 0 0 0 117.6V147a129.2 129.2 0 0 0 43.09 96.32Z'
      />
      <path
        fill='#608bd8'
        d='M330.4 83.65 325.82 81 216.27 181.73l91.88 79.62C322.63 273.9 328 292.2 322.4 313l8-4.61a39.2 39.2 0 0 0 19.6-34V117.6a39.2 39.2 0 0 0-19.6-33.95ZM170.3 224.72 43.71 127.06C29.44 116 23 99.45 25.93 80l-6.33 3.65A39.2 39.2 0 0 0 0 117.6v156.8a39.2 39.2 0 0 0 19.6 34l36.26 20.93Z'
      />
    </svg>
  )
}
