const BackgroundGraph = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='1800'
    height='600'
    viewBox='0 0 1800 600'
    {...props}
  >
    <path d='M806.615 369.024c-60 11.499-75-4.999-129-16.998-97.308-21.622-180.9 31.862-250.615 49.994-86.5 22.498-128-22.498-218-20.498-64.529 1.435-128.544 24.457-209 20.42v198.059h1800V0h-374c-120.23 110.342-231 153.046-404.39 153.046-147.506 0-104.961 194.89-214.995 215.978z' />
  </svg>
)

export default BackgroundGraph
