const ExclamatioMark = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    fill='none'
    viewBox='0 0 20 20'
    {...props}
  >
    <circle cx='10' cy='10' r='10' fill='#FB715F' opacity='.15' />
    <line
      x1='10'
      x2='10'
      y1='5'
      y2='10'
      stroke='#FF715E'
      strokeLinecap='round'
      strokeWidth='2'
    />
    <circle cx='10' cy='14.5' r='1.5' fill='#FF715E' />
  </svg>
)

export default ExclamatioMark
