const Triangle = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 8 6'
    width={8}
    height={6}
    {...props}
  >
    <path d='m4 0 4 6H0l4-6Z' />
  </svg>
)

export default Triangle
