import * as React from 'react'

const NearformLogo = (props: React.SVGAttributes<SVGElement>): JSX.Element => {
  return (
    <svg
      width={64}
      height={50}
      viewBox='0 0 64 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0 0h6.537l21.236 30.885V0h7.037v43.584h-6.537L7.037 12.699v30.885H0V0z'
        fill='currentColor'
      />
      <path d='M38.997 43.585H63.47v6.412H38.997v-6.412z' fill='currentColor' />
    </svg>
  )
}

export default NearformLogo
