const Triangle = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 48 48'
    width={48}
    height={48}
    {...props}
  >
    <circle opacity='.2' cx='24' cy='24' r='24' fill='#fff' />
    <path
      d='m13.719 22.668 8.456 9.274 12.698-16.595'
      stroke='#fff'
      strokeWidth='4'
    />
  </svg>
)

export default Triangle
