import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { LandingPage } from '../components/LandingPage'
import { REDIRECT } from '../utils/constants'

export default function Home(): JSX.Element {
  const router = useRouter()

  useEffect(() => {
    const redirect = localStorage?.getItem(REDIRECT)
    if (redirect) {
      localStorage.removeItem(REDIRECT)
      router.push(redirect)
    }
  }, [router])

  return <LandingPage />
}

export async function getStaticProps() {
  return {
    props: {},
  }
}
